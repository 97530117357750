import { oidcAPI } from 'api';
import {
  all,
  call,
  put,
  SagaReturnType,
  takeLeading,
} from 'redux-saga/effects';
import * as R from 'remeda';
import * as appActions from 'store/glue/app/actions';
import { sagaWrapper } from 'store/helpers';
import { ActionType } from 'typesafe-actions';

import * as userActions from './actions';
import * as userConstants from './constants';
const LOCAL_STORAGE_ITEM = 'token';

function* startLoginSaga(action: ActionType<typeof userActions.startLogin>) {
  function* saga() {
    const user: SagaReturnType<typeof oidcAPI.getUser> = yield call(
      oidcAPI.getUser,
    );

    if (!R.isDefined(user)) {
      localStorage.removeItem(LOCAL_STORAGE_ITEM);
      yield put(userActions.setToken(null));

      yield call(oidcAPI.signinRedirect);
      return;
    }

    const bearer = user.access_token;
    localStorage.setItem(LOCAL_STORAGE_ITEM, bearer);

    yield put(userActions.setToken(bearer));
    yield put(appActions.startInitialize());
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

function* startLogoutSaga(action: ActionType<typeof userActions.startLogout>) {
  function* saga() {
    localStorage.removeItem(LOCAL_STORAGE_ITEM);
    yield put(userActions.setToken(null));

    yield call(oidcAPI.signoutRedirect);
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

function* startVerifySaga(action: ActionType<typeof userActions.startVerify>) {
  function* saga() {
    const user: SagaReturnType<typeof oidcAPI.signinRedirectCallback> =
      yield call(oidcAPI.signinRedirectCallback);

    const bearer = user.access_token;
    localStorage.setItem(LOCAL_STORAGE_ITEM, bearer);
    yield put(userActions.setToken(bearer));
    yield put(appActions.startInitialize());
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

export function* watcher() {
  yield all([
    takeLeading(userConstants.START_LOGIN, startLoginSaga),
    takeLeading(userConstants.START_VERIFY, startVerifySaga),
    takeLeading(userConstants.START_LOGOUT, startLogoutSaga),
  ]);
}
