import axios from 'axios';
import { USE_MOCK } from 'config/app';
import { apiUtility } from 'utility';

import { baseEndpoint } from './config';
import mockedGetAggregatedActivityHoursJson from './mocked/getAggregatedActivityHours.json';
import mockedGetProducedActivitiesJson from './mocked/getProducedActivities.json';
import {
  transformActuals,
  transformAggregatedEmployeeProducedActivities,
  transformAggregatedProducedActivities,
  transformProducedActivities,
} from './transformers';

const getProducedActivities = (params: {
  companyId: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const { companyId, useMocked = USE_MOCK, bearerToken } = params;
  if (useMocked)
    return Promise.resolve(
      mockedGetProducedActivitiesJson as ReturnType<
        typeof transformProducedActivities
      >,
    );

  return axios
    .get<{
      producedActivities: API.ProducedActivity[];
      errorMessages: API.ErrorMessage[];
      startDate: string;
      endDate: string;
    }>(`${baseEndpoint}/producedactivities/${companyId}`, {
      headers: { Authorization: `Bearer ${bearerToken}` },
    })
    .then((response) => {
      const { data } = response;

      const transformed = transformProducedActivities(
        data.producedActivities,
        data.errorMessages,
      );
      return transformed;
    });
};

const getAggregatedEmployeeHours = (params: {
  companyId: string;
  bearerToken: string;
  useMocked?: boolean;
  dates: Time.Date[];
}) => {
  const { bearerToken, companyId, dates, useMocked = USE_MOCK } = params;

  if (useMocked) {
    // eslint-disable-next-line no-console
    console.warn('Get Aggregated EmployeeHour has no mock');
    return Promise.resolve({
      employeeHourDomain: { allIds: [], byId: {} },
    } as ReturnType<typeof transformAggregatedEmployeeProducedActivities>);
  }

  // Empty dates will result in all history in response,
  // Not ideal.
  if (dates.length === 0)
    return Promise.resolve({
      employeeHourDomain: { allIds: [], byId: {} },
    } as ReturnType<typeof transformAggregatedEmployeeProducedActivities>);

  const _dates: API.DateResolution[] = apiUtility.toDateResolution(dates);

  return axios
    .post<
      API.AggregatedProducedActivity[]
    >(`${baseEndpoint}/aggregatedactivities/${companyId}`, { resolution: 'employee', dates: _dates }, { headers: { Authorization: `Bearer ${bearerToken}` } })
    .then((response) => {
      const { data } = response;
      const transformed = transformAggregatedEmployeeProducedActivities({
        aggregatedProducedActivities: data,
      });

      return transformed;
    });
};
/**
 * We want to get all the history for now.
 * In the future we can ask for history relevant for specific date interval.
 * Logic & Synchronization is much easier if we just have everything.
 */
const getAggregatedActivityHours = (params: {
  companyId: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const { bearerToken, companyId, useMocked = USE_MOCK } = params;

  if (useMocked) {
    return Promise.resolve(
      mockedGetAggregatedActivityHoursJson as ReturnType<
        typeof transformAggregatedProducedActivities
      >,
    );
  }

  return axios
    .post<
      API.AggregatedProducedActivity[]
    >(`${baseEndpoint}/aggregatedactivities/${companyId}`, { resolution: 'activity' }, { headers: { Authorization: `Bearer ${bearerToken}` } })
    .then((response) => {
      const { data } = response;
      const transformed = transformAggregatedProducedActivities({
        aggregatedProducedActivities: data,
      });

      return transformed;
    });
};

const getActuals = (params: {
  companyId: string;
  zoneId?: string;
  subZoneId?: string;
  bearerToken: string;
  useMocked?: boolean;
}) => {
  const { bearerToken, companyId, zoneId, subZoneId } = params;

  return axios
    .post<
      API.Actual[]
    >(`${baseEndpoint}/actuals/${companyId}`, { zoneId, subZoneId }, { headers: { Authorization: `Bearer ${bearerToken}` } })
    .then((response) => {
      const { data } = response;
      const transformed = transformActuals({ actuals: data });
      return transformed;
    });
};

export {
  getActuals,
  getAggregatedActivityHours,
  getAggregatedEmployeeHours,
  getProducedActivities,
};
