import { routes } from 'router/constants';

const USE_MOCKED_USER = false;
const USE_MOCK = false || USE_MOCKED_USER;

const DETECT_MUTATIONS = false;
const LOG_SAGA_TIME = false;
const SLOW_DOWN_SAGA = false;
const REACT_STRICT_MODE = false;

const START_PAGE = routes.forecast;

const COMPANY_ID = 'f63fd3c6-2d56-4c84-80ad-a65de5a62d84';

export {
  COMPANY_ID,
  DETECT_MUTATIONS,
  LOG_SAGA_TIME,
  REACT_STRICT_MODE,
  SLOW_DOWN_SAGA,
  START_PAGE,
  USE_MOCK,
  USE_MOCKED_USER,
};
