import { actualAPI, companyAPI, forecastAPI } from 'api';
import { all, call, put, SagaReturnType } from 'redux-saga/effects';
import * as R from 'remeda';
import { activityHourActions } from 'store/aggregated/activityHour';
import { externalActions } from 'store/aggregated/external';
import { forecastActions } from 'store/aggregated/forecast';
import { internalActions } from 'store/aggregated/internal';
import { predictionActions } from 'store/aggregated/prediction';
import { activityActions } from 'store/data/activity';
import { activityTypeActions } from 'store/data/activityType';
import { areaActions } from 'store/data/area';
import { branchActions } from 'store/data/branch';
import { companyActions } from 'store/data/company';
import { distributionCenterActions } from 'store/data/distributionCenter';
import { employeeActions } from 'store/data/employee';
import { subZoneActions } from 'store/data/subZone';
import { suggestionActions } from 'store/data/suggestion';
import { zoneActions } from 'store/data/zone';

function* initializeOrganization(args: {
  companyId: string;
  bearerToken: string;
}) {
  const { companyId, bearerToken } = args;

  const organization: SagaReturnType<typeof companyAPI.getOrganization> =
    yield call(companyAPI.getOrganization, {
      companyId,
      bearerToken,
    });

  if (!R.isDefined(organization)) return;
  const {
    areaDomain,
    activityDomain,
    activityTypeDomain,
    branchDomain,
    // branchIds,
    subZoneDomain,
    zoneDomain,
    distributionCenterDomain,
    employeeDomain,
    // employeeIds,
    // activityTypeIds,
  } = organization;

  yield all([
    put(branchActions.setDomain(branchDomain)),
    put(distributionCenterActions.setDomain(distributionCenterDomain)),
    put(areaActions.setDomain(areaDomain)),
    put(employeeActions.setDomain(employeeDomain)),
    put(activityActions.setDomain(activityDomain)),
    put(activityTypeActions.setDomain(activityTypeDomain)),
    put(subZoneActions.setDomain(subZoneDomain)),
    put(zoneActions.setDomain(zoneDomain)),
  ]);
  yield put(
    companyActions.update({
      id: companyId,
      branchIds: branchDomain.allIds,
      employeeIds: employeeDomain.allIds,
      activityTypeIds: activityTypeDomain.allIds,
    }),
  );
}

function* initializeActual(args: { companyId: string; bearerToken: string }) {
  const { companyId, bearerToken } = args;

  if (!R.isDefined(companyId)) return;

  const {
    activityHourDomain,
  }: SagaReturnType<typeof actualAPI.getAggregatedActivityHours> = yield call(
    actualAPI.getAggregatedActivityHours,
    {
      bearerToken,
      companyId,
    },
  );
  const {
    externalDomain,
    internalDomain,
  }: SagaReturnType<typeof actualAPI.getActuals> = yield call(
    actualAPI.getActuals,
    {
      companyId,
      bearerToken,
    },
  );

  yield put(internalActions.setDomain(internalDomain));
  yield put(externalActions.setDomain(externalDomain));

  yield all([put(activityHourActions.setDomain(activityHourDomain))]);
}

function* initializeForecast(args: { companyId: string; bearerToken: string }) {
  const { companyId, bearerToken } = args;

  if (!R.isDefined(companyId)) return;

  const { forecastDomain }: SagaReturnType<typeof forecastAPI.getForecasts> =
    yield call(forecastAPI.getForecasts, {
      companyId,
      bearerToken,
    });

  const { predictionDomain }: SagaReturnType<typeof forecastAPI.getPrediction> =
    yield call(forecastAPI.getPrediction, {
      companyId,
      bearerToken,
    });

  yield put(forecastActions.setDomain(forecastDomain));
  yield put(predictionActions.setDomain(predictionDomain));
}

function* initializeImprovements(args: {
  companyId: string;
  bearerToken: string;
}) {
  const { companyId, bearerToken } = args;

  if (!R.isDefined(companyId)) return;

  const suggestionDomain: SagaReturnType<typeof companyAPI.getSuggestions> =
    yield call(companyAPI.getSuggestions, {
      bearerToken,
      companyId,
    });

  yield put(suggestionActions.setDomain(suggestionDomain));
}

export {
  initializeActual,
  initializeForecast,
  initializeImprovements,
  initializeOrganization,
};
