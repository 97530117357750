import { actualAPI } from 'api';
import { COMPANY_ID } from 'config/app';
import {
  all,
  call,
  delay,
  put,
  SagaReturnType,
  select,
  takeLeading,
} from 'redux-saga/effects';
import * as R from 'remeda';
import { employeeHourActions } from 'store/aggregated/employeeHour';
import { userSelectors } from 'store/glue/user';
import { sagaWrapper } from 'store/helpers';
import { ActionType } from 'typesafe-actions';

import * as flexHoursActions from './actions';
import * as flexHoursConstants from './constants';

/**
 *
 * When selecting dates from calendar modal.
 * - always fetches and replaces aggregatedEmployeeHours.
 */
function* startSelectDatesSaga(
  action: ActionType<typeof flexHoursActions.startSelectDates>,
) {
  function* saga() {
    yield delay(1);
    const { selectedDates } = action.payload;

    const bearerToken: ReturnType<typeof userSelectors.bearerToken> =
      yield select(userSelectors.bearerToken);
    if (!R.isDefined(bearerToken)) return;

    yield put(flexHoursActions.setSelectedDates(selectedDates));
    const {
      employeeHourDomain,
    }: SagaReturnType<typeof actualAPI.getAggregatedEmployeeHours> = yield call(
      actualAPI.getAggregatedEmployeeHours,
      {
        bearerToken,
        companyId: COMPANY_ID,
        useMocked: false,
        dates: selectedDates,
      },
    );

    yield put(employeeHourActions.setDomain(employeeHourDomain));
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

export function* watcher() {
  yield all([
    takeLeading(flexHoursConstants.START_SELECT_DATES, startSelectDatesSaga),
  ]);
}
