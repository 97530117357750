import { forecastAPI } from 'api';
import { COMPANY_ID } from 'config/app';
import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLeading,
} from 'redux-saga/effects';
import * as R from 'remeda';
import { forecastActions as aggregatedForecastActions } from 'store/aggregated/forecast';
import { predictionActions as aggregatedPredictionActions } from 'store/aggregated/prediction';
import { userSelectors } from 'store/glue/user';
import { sagaWrapper } from 'store/helpers';
import { ActionType } from 'typesafe-actions';

import * as forecastActions from './actions';
import * as forecastConstants from './constants';

function* startCreateForecast(
  action: ActionType<typeof forecastActions.startCreateForecast>,
) {
  function* saga() {
    const { zoneId, date, value } = action.payload;

    const bearerToken: ReturnType<typeof userSelectors.bearerToken> =
      yield select(userSelectors.bearerToken);
    if (!R.isDefined(bearerToken)) return;

    const forecast: SagaReturnType<typeof forecastAPI.createForecast> =
      yield call(forecastAPI.createForecast, {
        bearerToken,
        companyId: COMPANY_ID,
        zoneId,
        date,
        value,
        useMocked: false,
      });

    yield put(aggregatedForecastActions.add(forecast));
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

function* startCreatePrediction(
  action: ActionType<typeof forecastActions.startCreatePrediction>,
) {
  function* saga() {
    const { zoneId, date, value } = action.payload;

    const bearerToken: ReturnType<typeof userSelectors.bearerToken> =
      yield select(userSelectors.bearerToken);
    if (!R.isDefined(bearerToken)) return;

    const prediction: SagaReturnType<typeof forecastAPI.createPrediction> =
      yield call(forecastAPI.createPrediction, {
        bearerToken,
        companyId: COMPANY_ID,
        zoneId,
        date,
        value,
        useMocked: false,
      });

    yield put(aggregatedPredictionActions.add(prediction));
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

export function* watcher() {
  yield all([
    takeLeading(forecastConstants.START_CREATE_FORECAST, startCreateForecast),
    takeLeading(
      forecastConstants.START_CREATE_PREDICTION,
      startCreatePrediction,
    ),
  ]);
}
