import { QrCodeScannerOutlined } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { COMPANY_ID } from 'config/app';
import React, { FunctionComponent, useState } from 'react';

import QRCodeModal from './QRCodeModal';

const PageActions: FunctionComponent = () => {
  const [selectingDates, setSelectingDates] = useState(false);

  return (
    <>
      <QRCodeModal
        isOpen={selectingDates}
        onClose={() => setSelectingDates(false)}
        companyId={COMPANY_ID}
      />

      <Button
        onClick={() => setSelectingDates(true)}
        sx={(theme) => ({
          marginLeft: theme.distances.medium,
          color: theme.palette.text.secondary,
        })}
        endIcon={<QrCodeScannerOutlined />}
        variant="text"
        disableRipple
        color="inherit"
      >
        <Typography variant="action1">Connect </Typography>
      </Button>
    </>
  );
};

export default PageActions;
