import { companyAPI } from 'api';
import { COMPANY_ID } from 'config/app';
import {
  all,
  call,
  put,
  SagaReturnType,
  select,
  takeLeading,
} from 'redux-saga/effects';
import * as R from 'remeda';
import { companyActions } from 'store/data/company';
import { sagaWrapper } from 'store/helpers';
import { ActionType } from 'typesafe-actions';

import * as userSelectors from '../user/selectors';
import * as appActions from './actions';
import * as appConstants from './constants';
import * as appHelpers from './helpers';

function* startInitializeSaga(
  action: ActionType<typeof appActions.startInitialize>,
) {
  function* saga() {
    const bearerToken: ReturnType<typeof userSelectors.bearerToken> =
      yield select(userSelectors.bearerToken);
    if (!R.isDefined(bearerToken)) return;

    const companyDomain: SagaReturnType<typeof companyAPI.getCompanies> =
      yield call(companyAPI.getCompanies, { bearerToken });

    yield put(companyActions.setDomain(companyDomain));

    yield call(appHelpers.initializeOrganization, {
      companyId: COMPANY_ID,
      bearerToken,
    });
    yield call(appHelpers.initializeActual, {
      companyId: COMPANY_ID,
      bearerToken,
    });

    yield call(appHelpers.initializeForecast, {
      companyId: COMPANY_ID,
      bearerToken,
    });

    yield call(appHelpers.initializeImprovements, {
      companyId: COMPANY_ID,
      bearerToken,
    });
  }

  yield call(sagaWrapper, { saga, type: action.type });
}

export function* watcher() {
  yield all([takeLeading(appConstants.START_INITIALIZE, startInitializeSaga)]);
}
