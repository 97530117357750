import { USE_MOCKED_USER } from 'config/app';
import { User, UserManager } from 'oidc-client-ts';

import { localSettings } from './config';

/**
 * 1. The Relying Parties (RP) (Client) sends and request to the OpenID Provider (OP)
 * 2. OP authenticates the end user and obtains authorization (redirect page)
 * 3. OP responds with an ID Token (JWT) and ususally an Access Token (Bearer?)
 * 4. RP can send rquest with the access token to the userinfo endpoint
 * 5. User Info endpoint returns claims about the end-user
 */
const fakeUser: Pick<User, 'access_token'> = {
  access_token: 'Mocke Karlsson',
};

const userManager = new UserManager(localSettings);

// Get authenticated user
const getUser = () => {
  if (USE_MOCKED_USER) return Promise.resolve(fakeUser);
  return userManager.getUser();
};

// Redirect to signin page
const signinRedirect = () => {
  if (USE_MOCKED_USER) return Promise.resolve();
  return userManager.signinRedirect();
};

// When redirected after succes
const signinRedirectCallback = () => {
  if (USE_MOCKED_USER) return Promise.resolve(fakeUser);
  return userManager.signinRedirectCallback();
};

const signoutRedirect = () => {
  if (USE_MOCKED_USER) return Promise.resolve();
  return userManager.signoutRedirect();
};

export { getUser, signinRedirect, signinRedirectCallback, signoutRedirect };
